// useSoftDeleteMessage.js
import { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';

const useSoftDeleteMessage = () => {
  const [isSoftDeleted, setIsSoftDeleted] = useState(false);
  const [error, setError] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const navigate = useNavigate();


  const softDeleteMessage = async () => {
    try {
      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, { soft_delete: true });
      setIsSoftDeleted(true);
    } catch (error) {
      setError('Error updating soft_delete property. Please try again.');
    }
  };

  useEffect(() => {
    if (messageId) {
      softDeleteMessage();
      navigate('/protected/admin');
    }
  }, [messageId]);

  return { isSoftDeleted, error, softDeleteMessage, setMessageId };
};

export default useSoftDeleteMessage;

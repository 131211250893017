import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Socials from './Socials/Socials';
import { useAuth } from '../../hooks/auth';
import useFirebaseLogout from '../../hooks/useLogout';
import ColourSwitcher from '../colourSwitcher/ColourSwitcher';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { user } = useAuth(); // Get user information
  const { logout } = useFirebaseLogout(); // Get logout function

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    const header = document.querySelector('.header');

    // Function to update the header's background on scroll
    const handleScroll = () => {
      if (window.scrollY > 0) {
        header.classList.add('scrolled', 'white-text');
      } else {
        header.classList.remove('scrolled', 'white-text');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <header className="header">
      <div className="logo">
        <Link to="/home" className="text-decoration-none white-text">
          {`<Byte Buddies>`}
        </Link>
      </div>
      <div className='mobile-hidden' style={{width: '60%'}}></div>
      <ColourSwitcher />
      <div className="menu-container ">
        <div className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
          <div className={` navBurger ${isNavOpen ? 'open' : ''}`}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <nav className={`dropdown-nav white-black-bg ${isNavOpen ? 'open' : ''}`}>
          <div id="background" className={`header-background`}></div>

          <ul className='main-menu'>
            <li className='nav-item primary-text'><Link to="/home" onClick={closeNav} className='white-text'>Home</Link></li>
            <li className='nav-item primary-text'><Link to="/about" onClick={closeNav} className='white-text'>About</Link></li>
            <li className='nav-item primary-text'><Link to="/services" onClick={closeNav} className='white-text'>Services</Link></li>
            <li className='nav-item primary-text'><Link to="/work" onClick={closeNav} className='white-text'>Our Work</Link></li>
            <li className='nav-item primary-text'><Link to="/contact" onClick={closeNav} className='white-text'>Contact</Link></li>
            {user && (
              <li className='nav-item logout-button' onClick={logout}>
                Logout
              </li>
            )}
          </ul>
          <div className='social-menu'>
            <div className='social-contact'>
              <h5 className="title-block mb-10">Contact</h5>
              <p className="links over-hidden primary-text">E:
                <a href="mailto:bytebuddies1@gmail.com" className='white-text'>bytebuddies1@gmail.com</a>
              </p>
              <p className="links over-hidden primary-text">T:
                <a href="tel:+5199961094" className='white-text'>519-996-1094</a>
              </p>
            </div>
            <div className="social-links">
              <h5 className="title-block mb-10">Follow us</h5>
              <div className='socials-list'>
                <Socials />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <div className="hide mb-5">.</div>
    <div className="hide mb-2">.</div>
    </>
    
  );
};

export default Header;

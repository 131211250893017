import { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { app } from '../lib/firebase';

const useComments = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const firestore = getFirestore(app);
    const collectionRef = collection(firestore, 'comments');

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      const commentsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(commentsData);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup by unsubscribing from the snapshot listener when the component unmounts
  }, []);

  return { comments, loading };
};

export default useComments;

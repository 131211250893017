import { useState } from 'react';
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { app, storage } from '../lib/firebase';

// Function for fetching projects
export const fetchProjects = async () => {
  const firestore = getFirestore(app);
  const collectionRef = collection(firestore, 'projects');

  try {
    console.log('Fetching projects...');
    const querySnapshot = await getDocs(collectionRef);
    const projectsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log('Fetched projects:', projectsData);
    return projectsData;
  } catch (error) {
    /* console.error('Error fetching projects:', error); */
    return [];
  }
};

// Function for adding a new project
export const addProject = async (newProject) => {
  try {
    const firestore = getFirestore(app);
    const collectionRef = collection(firestore, 'projects');

    // Add timestamp to the newProject
    newProject.date = serverTimestamp();

    // Upload the image if it exists
    if (newProject.image) {
      const storageRef = ref(storage, 'projects/' + newProject.image.name);
      await uploadBytes(storageRef, newProject.image);

      // Update the newProject with the image URL
      newProject.image_url = await getDownloadURL(storageRef);

      // Remove the image field from the newProject to avoid duplication
      delete newProject.image;
    }

    // Add the newProject to the 'projects' collection in Firestore
    await addDoc(collectionRef, newProject);

    return true;
  } catch (error) {
    /* console.error('Error adding project:', error); */
    return false;
  }
};

// Custom hook for removing a project
export const useRemoveProject = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const removeProject = async (projectId, imageURL) => {
    setLoading(true);

    try {
      const firestore = getFirestore(app);
      const projectRef = doc(firestore, 'projects', projectId);

      // Delete the project document from Firestore
      await deleteDoc(projectRef);

      // Delete the associated image from storage if it exists
      if (imageURL) {
        const storageRef = ref(storage, imageURL);
        await deleteObject(storageRef);
      }

      setLoading(false);
      return true;
    } catch (error) {
      /* console.error('Error removing project:', error); */
      setError(error.message);
      setLoading(false);
      return false;
    }
  };

  return { removeProject, loading, error };
};

import { createBrowserRouter, useParams } from "react-router-dom";
import React from "react";


import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer/Footer.jsx";


import Layout from "../components/layout";
import Home from "../components/home/Home";
import About from "../components/about/About";
import Contact from "../components/contact/Contact.jsx";
import Login from "../components/auth/Login.jsx";
import Projects from "../components/projects/Projects.jsx";
import AdminIndex from "../components/auth/dashboard/AdminIndex.jsx";
import Message from "../components/auth/messages/Message.jsx";
import EditProject from "../components/auth/projects/EditProject.jsx";

import ViewProjects from "../components/auth/projects/ViewProjects.jsx";
import Project from "../components/projects/Project.jsx";
import AddProject from "../components/auth/projects/AddProject.jsx";
import Admin from "../components/auth/dashboard/Admin.jsx";

import CursorFollow from "../components/cursor/CursorFollow";
import MessageBoard from "../components/auth/messages/MessageBoard.jsx";
import Services from "../components/services/Services.jsx";






export const ROOT = "/";
export const ABOUT = "/about";
export const HOME = "/home";
export const CONTACT = "/contact";
export const LOGIN = "/login";
export const WORK = "/work";
export const SERVICES = "/services";
export const PROJECT = "/project/:id";

// Protected route
export const PROTECTED = "/protected";
export const MESSAGES = "/protected/messages";
export const MESSAGE = "/protected/message/:id";
export const EDIT = "/protected/edit/:id";
export const DELETE = "/protected/delete/:id";
export const PROJECTS_ADMIN = "/protected/projects/";
export const ADD_PROJECT = "/protected/add/";
export const ADMIN = "/protected/admin";



// create routes
export const router = createBrowserRouter([
    { 
      path: ROOT,
      element: <Layout />,
      children: [
        {
          path: ABOUT,
          element: <About />
        },
        {
          path: HOME,
          element: <Home />
        },
        {
          path: WORK,
          element: <Projects />
        },
        {
          path: CONTACT,
          element: <Contact />
        },
        {
          path: PROJECT,
          element: <Project />
        },
        {
          path: SERVICES,
          element: <Services />
        },
        {
          path: LOGIN,
          element: <Login />
        },
      ]
    },
    { 
      path: PROTECTED,
      element: <AdminIndex />, 
      children: [
        {
          path: ADMIN,
          element: <Admin />
        },
        {
            path: MESSAGE,
            element: <Message />
        },
        {
          path: ADD_PROJECT,
          element: <AddProject />
        },
        {
          path: EDIT,
          element: <EditProject />
        },
        {
          path: MESSAGES,
          element: <MessageBoard />
        },
        {
          path: PROJECTS_ADMIN,
          element: <ViewProjects />
        },
      ],
  },
    { path: "*", element: <NotFound /> }
  ]);


  function NotFound() {
    const backgroundStyle = {
      width: "100%",
      height: "100vh",
      backgroundImage: "url(https://upload.wikimedia.org/wikipedia/en/2/22/Charlie_Brown.png)", 
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      textAlign: "center",
      fontFamily: "Arial, sans-serif",
    };
  
    const overlayStyle = {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.65)", // White background color at 65% opacity
      zIndex: -1,
    };
  
    const headerStyle = {
      fontSize: "2.5rem",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Black text shadow
      marginBottom: "10px",
    };
  
    const paragraphStyle = {
      fontSize: "1.2rem",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)", // Black text shadow
      maxWidth: "400px",
      margin: "0 auto",
      marginBottom: "20px",
    };
  
    return (
        <div>
        <Header />
        <CursorFollow />
        <div style={backgroundStyle}>
            <div style={overlayStyle}></div>
            <div style={{ padding: "5%", borderRadius: "10px" }}>
                <h1 style={headerStyle} className="wizard-font">Alas! You've strayed into the Enchanted Realm.</h1>
                <p style={paragraphStyle} className="wizard-font">It appears you've taken a detour from your intended path.</p>
                <p style={paragraphStyle} className="wizard-font">
                    Fret not, dear traveler! You can return to your journey by{" "}
                    <a href="/" className="magic-link">following the mystical path</a> or savor some enchanted moments. ✨🧙‍♂️
                </p>
            </div>
        </div>
        <Footer />
    </div>
    
        
    );
  }
  
  
  export default router;
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useFirebaseEmailLogin from '../../hooks/useLogin';
import useFirebaseLogout from '../../hooks/useLogout';
import { emailValidate, passwordValidate } from '../../util/form-validate';
import { ADMIN } from '../../lib/routes';
import { useNavigate } from 'react-router-dom';

function Login() {
  const { user, error, login } = useFirebaseEmailLogin();
  const { logout } = useFirebaseLogout();
  const [successLoginToastShown, setSuccessLoginToastShown] = useState(false);
  const [successSignOutToastShown, setSuccessSignOutToastShown] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const handleLogin = async (data) => {
    const { email, password } = data;

    if (errors.email || errors.password) {
      // Validation failed, show an error toast
      toast.error('Please correct the form errors.', {
        position: 'top-center',
        autoClose: 5000,
        closeButton: true,
        hideProgressBar: false,
        closeOnClick: true,
        className: 'mb-5',
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    // Validation passed, attempt to log in
    login(email, password);
  };

  useEffect(() => {
    // Check for a successful login after a user state change
    if (user && !successLoginToastShown) {
      handleSuccessfulLogin();
      navigate(ADMIN); // Redirect to PROJECTS_ADMIN after successful login
    }

    // Check for errors and show a toast for unsuccessful form submissions
    if (error) {
      toast.error('Login failed. Please check your credentials and try again.', {
        position: 'top-center',
        autoClose: 5000,
        closeButton: true,
        hideProgressBar: false,
        closeOnClick: true,
        className: 'mb-5',
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [user, successLoginToastShown, error, navigate]);

  const handleSuccessfulLogin = () => {
    toast.success('Successfully Logged In!', {
      position: 'top-center',
      autoClose: 5000,
      closeButton: true,
      className: 'mb-5',
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });

    // Mark the success login toast as shown
    setSuccessLoginToastShown(true);
  }

  const handleSuccessfulSignOut = () => {
    toast.success('Successfully Signed Out!', {
      position: 'top-center',
      autoClose: 5000,
      closeButton: true,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });

    // Mark the success sign-out toast as shown
    setSuccessSignOutToastShown(true);
  }

  return (
    <div className="container py-5">
      <div className="row justify-content-center pt-5">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              {user ? (
                <div>
                  <p>Welcome, {user.email}!</p>
                  <button onClick={() => {
                    logout();
                    // Show the success sign-out toast when logging out
                    if (!successSignOutToastShown) {
                      handleSuccessfulSignOut();
                    }
                  }}>Logout</button>
                </div>
              ) : (
                <div>
                  <h2>Login</h2>
                  <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        name="email"
                        {...register('email', emailValidate)}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        placeholder="Enter your email"
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email.message}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input
                        type="password"
                        name="password"
                        {...register('password', passwordValidate)}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        placeholder="Enter your password"
                      />
                      {errors.password && (
                        <div className="invalid-feedback">{errors.password.message}</div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-primary my-2">
                      Login
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;

import React from 'react';
import './StarRating.css';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const partialStar = rating % 1;

  return (
    <div className="star-rating justify-content-center">
      {[...Array(fullStars)].map((_, index) => (
        <span key={index} className="star full-star">&#9733;</span>
      ))}
      {partialStar > 0 && partialStar < 1 && (
        <span className="star partial-star" style={{ width: `${partialStar * 100}%` }}>
          &#9733;
        </span>
      )}
    </div>
  );
};

export default StarRating;

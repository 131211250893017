import { useState, useEffect } from 'react';
import { app, storage } from '../lib/firebase';
import { getFirestore, collection, addDoc, getDocs, serverTimestamp  } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const useFirebaseMessages = () => {
  // State to hold the messages
  const [messages, setMessages] = useState([]);
  
  // Get Firestore instance
  const firestore = getFirestore(app);

  // Reference to the 'messages' collection in Firestore
  const collectionRef = collection(firestore, 'messages');

  // Function to fetch messages from Firestore
  const fetchMessages = async () => {
    try {
      // Retrieve the documents from the 'messages' collection
      const querySnapshot = await getDocs(collectionRef);
      
      // Map the documents to message objects
      const messagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Set the messages state with the retrieved data
      setMessages(messagesData);
    } catch (error) {
     /*  console.error('Error fetching messages:', error); */
    }
  };

  // Effect hook to fetch messages when the component mounts
  useEffect(() => {
    fetchMessages();
  }, []);

  

  // Function to add a new message to Firestore
  const addMessage = async (newMessage) => {
    try {
      // Add timestamp to the newMessage
      newMessage.date = serverTimestamp();
      //add soft_delete value
      newMessage.soft_delete = false;
      newMessage.read = false;
      newMessage.messageId = uuidv4();
      // Upload the image if it exists
      if (newMessage.image) {
        const storageRef = ref(storage, 'images/' + newMessage.image.name);
        await uploadBytes(storageRef, newMessage.image);
  
        // Update the newMessage with the image URL
        newMessage.image_url = await getDownloadURL(storageRef);
  
        // Remove the image field from the newMessage to avoid duplication
        delete newMessage.image;
      }
  
      // Add the newMessage to the 'messages' collection in Firestore
      const docRef = await addDoc(collectionRef, newMessage);
  
      // Update the messages state with the new message
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: docRef.id, ...newMessage },
      ]);
    } catch (error) {
      /* console.error('Error adding message:', error); */
    }
  };

  // Return the messages and the function to add a new message for external use
  return { messages, addMessage };
};

export default useFirebaseMessages;

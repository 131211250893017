import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Footer.css'; // Assuming you have a CSS file for styling


const Row3 = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling effect
    });
  };

  return (
    <>
    <div className="row footer-border">
      <div className="col-11 footer-3 text-center white-text">
        <p>© ByteBuddies Studios ™ <br /> All Rights Reserved</p>
        {/* Add the trademark symbol using the HTML entity */}
      </div>
      <div className="col-1 text-right footer-arrow">
        <i onClick={scrollToTop} className="fa fa-arrow-up fs-1 arrow-button" aria-hidden="true"></i>  
      </div>
    </div>
    </>
    
  );
};

export default Row3;

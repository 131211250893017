import React, { useEffect, useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../lib/firebase';
import Loading from '../loading/Loading';
import './Project.css';

const Project = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const itemRef = doc(db, 'projects', id);
        const itemDoc = await getDoc(itemRef);
        if (itemDoc.exists()) {
          const item = itemDoc.data();
          setItemData(item);
        } else {
         /*  console.error(`Item with ID ${id} not found.`); */
         return null;
        }
      } catch (error) {
        /* console.error('Error fetching item data:', error); */
        setError('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItemData();
  }, [id]);

  return (
    <div className="container mt-5">
      {itemData ? (
        <>
        <div className="mt-5 hide mobile-hidden">.</div>
          <h1 className="project-name white-text display-2">{itemData.title}</h1>
          <div className="row justify-content-center">
            {loading ? (
              <div className="col-md-8 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="row white-text" style={{width: '85%'}}>
                <div className="col-md-12">
                  <p className='prcontent'>{itemData.description}</p>
                  <p className='prcontent'><span className='prheader primary-text'>Technologies:</span> {itemData.technologies}</p>
                  <p className='prcontent'><span className='prheader primary-text'>Date Published:</span> {itemData.date?.toDate().toLocaleDateString()}</p>
                  <hr />
                </div>
              </div>
            )}
          </div>
          {itemData.project_url && (
            <div className="row justify-content-center">
              <div className="col-md-12">
                <iframe
                  src={itemData.project_url}
                  title="Live Demo"
                  width="100%"
                  height="600px"  
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
  
};

export default Project;
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Edit.css';


const EditProject = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState(null);
  const navigate = useNavigate();
  const [technologies, setTechnologies] = useState(itemData ? itemData.technologies.split(',') : []);
  const [currentTechnology, setCurrentTechnology] = useState('');

  const handleTechnologiesChange = (event) => {
    setCurrentTechnology(event.target.value);
  };

  const handleAddTechnology = () => {
    if (currentTechnology.trim() !== '') {
      setTechnologies((prevTechnologies) => [...prevTechnologies, currentTechnology]);
      setCurrentTechnology('');
    }
  };

  const handleRemoveTechnology = (index) => {
    setTechnologies((prevTechnologies) => {
      const updatedTechnologies = [...prevTechnologies];
      updatedTechnologies.splice(index, 1);
      return updatedTechnologies;
    });
  };
  

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const itemRef = doc(db, 'projects', id);
        const itemDoc = await getDoc(itemRef);
        if (itemDoc.exists()) {
          const item = itemDoc.data();
          setItemData(item);

          // Set initial state for technologies when itemData is available
          setTechnologies(item.technologies.split(','));
        } else {
          /* console.error(`Item with ID ${id} not found.`); */
          return false;
        }
      } catch (error) {
        /* console.error('Error fetching item data:', error); */
        return false;
      }
    };

    fetchItemData();
  }, [id]);

  // Add a function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Extract values from form
    const updatedTitle = event.target.title.value;
    const updatedDescription = event.target.description.value;
    const updatedProjectUrl = event.target.project_url.value;
    const updatedPublishedBy = event.target.publishedBy.value;
  
    // Update data in Firestore
    const itemRef = doc(db, 'projects', id);
    try {
      await updateDoc(itemRef, {
        title: updatedTitle,
        description: updatedDescription,
        project_url: updatedProjectUrl,
        technologies: technologies.join(', '), // Join technologies array into a comma-separated string
        publishedBy: updatedPublishedBy,
        // Update other fields as needed...
      });
  
      // Redirect to the project details page after successful update
      navigate(`/protected/projects`);
    } catch (error) {
      /* console.error('Error updating item data:', error); */
      return false;
    }
  };

  return (
    <div className="container mt-5">
      {itemData ? (
        <div className="card black-white-bg dark-text">
          {/* Display hero image */}
          <img src={itemData.image_url} className="card-img-top h-100" alt={itemData.title} />

          {/* Editable form section */}
          <div className="card-body">
            <form onSubmit={handleFormSubmit}>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">Title:</label>
                <input type="text" className="form-control" id="title" defaultValue={itemData.title} />
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">Description:</label>
                <textarea className="form-control" id="description" defaultValue={itemData.description}></textarea>
              </div>

              <div className="mb-3">
                <label htmlFor="project_url" className="form-label">Project URL:</label>
                <input type="text" className="form-control" id="project_url" defaultValue={itemData.project_url} />
              </div>

              <div className="mb-3">
                <label htmlFor="technologies" className="form-label">
                  Technologies (enter technology, press enter):
                </label>
                
                  <input
                    type="text"
                    className="form-control"
                    id="technologies"
                    value={currentTechnology}
                    onChange={handleTechnologiesChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddTechnology();
                      }
                    }}
                    onBlur={handleAddTechnology}
                  />
                  <div className="d-flex flex-wrap">
                  {technologies.map((tech, index) => (
                    <div key={index} className="technology-tag bg-secondary rounded p-2 m-1">
                      <span className="text-light">{tech}</span>
                      <button
                        type="button"
                        className="close black-white-bg text-danger fs-6 fw-bold mx-2 btn-danger"
                        onClick={() => handleRemoveTechnology(index)}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="publishedBy" className="form-label">Published By:</label>
                <input type="text" className="form-control" id="publishedBy" defaultValue={itemData.publishedBy} />
              </div>

              {/* Submit button */}
              <button type="submit" className="about-button primary">Save Changes</button>
            </form>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default EditProject;

import React, { useEffect, useState } from "react";
import './HomeSummary.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomeSummary = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [fixedPosition, setFixedPosition] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollPosition = window.scrollY;
      setScrollPosition(newScrollPosition);
  
      // Set fixedPosition to true when scroll position is greater than or equal to 1100
      setFixedPosition(newScrollPosition >= 1100);
  
      // Set translateY for the background div
      const backgroundElement = document.getElementById("background");
      const parentContainer = document.getElementById("parent-container"); // Change to the actual ID of the parent container
      if (backgroundElement && parentContainer) {
        const maxTranslateY = parentContainer.offsetHeight - backgroundElement.offsetHeight;
        const translateY = Math.min(0, Math.max(-newScrollPosition, -maxTranslateY));
        backgroundElement.style.transform = `translateY(${translateY}px)`;
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const translateX = fixedPosition ? -50 * (scrollPosition - 250) / 250 : 0;
  const rotateY = fixedPosition ? (scrollPosition - 1100) / 8 : 0;
  const opacity = fixedPosition ? Math.max(0, 1 - Math.abs(rotateY) / 15) : 1;

  const leftStyles = () => {
    if (window.innerWidth > 992) {
      return {
        transform: `perspective(1000px) rotateY(${rotateY}deg) translateX(${translateX}px)`,
        opacity: opacity,
        transition: fixedPosition ? "transform 2s ease, opacity 2s ease" : "none",
      };
    } else {
      return {};
    }
  };
  const rightStyles = () => {
    if (window.innerWidth > 992) {
      return {
        transform: `perspective(1000px) rotateY(${-rotateY}deg) translateX(${-translateX}px)`,
        opacity: opacity,
        transition: fixedPosition ? "transform 2s ease, opacity 2s ease" : "none"
      };
    } else {
      return {};
    }
  };

  return (
    <>
    <div className={`HomeSummary review-font ${fixedPosition ? 'fixed' : ''}`}>
      <div className="container white-text">
        <div className="row" id="hover-container">
          <div
            className={`col-lg-6 slide-left ${fixedPosition ? 'left-saloon' : ''}`}
            id="left-column"
            style={ leftStyles() }
          >
            <img
              src="../../media/desktop.png" 
              alt="Web Development Company"
              className="img-fluid rounded my-3"
            />
          </div>
          <div
            className={`col-lg-6 slide-right ${fixedPosition ? 'right-saloon' : ''}`}
            id="right-column"
            style={ rightStyles() }
          >
            <h2 className="mb-4 fs-3 fw-bold">Your Web Development Partner</h2>
            <p className="fs-6">
              At <span className="primary-text fw-bold">ByteBuddies</span>, we specialize in developing and maintaining high-quality company websites and internal applications. Our team is dedicated to following modern web standards, utilizing the latest technologies, and adhering to best practices to ensure that your digital presence is cutting-edge.
            </p>
            <p className="fs-6">
              We take pride in our commitment to excellence, delivering web solutions that not only meet but exceed industry standards. Our expertise extends to creating websites and applications that are not only visually appealing but also optimized for high-scoring SEO, enhancing your online visibility and reach.
            </p>
            <p className="fs-6">
              Partner with us for a seamless web development experience, where your goals and vision are transformed into a compelling online presence. Let's elevate your digital journey together.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default HomeSummary;

import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer/Footer';
import CursorFollow from '../cursor/CursorFollow';
import { LOGIN, ADMIN } from '../../lib/routes'; // Import the LOGIN and ADMIN routes
import { useAuth } from '../../hooks/auth'; // Import the useAuth hook
import Loading from '../loading/Loading';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading } = useAuth(); // Use the useAuth hook to get user data

  useEffect(() => {
    // Check if the current path is '/'
    if (location.pathname === '/') {
      // Redirect to '/home' when the user manually refreshes the page
      window.location.href = '/home';
    }

    if (!isLoading) {
      if (location.pathname.startsWith('/protected') && !user) {
        // Redirect to the LOGIN route when the user is not authenticated
        navigate(LOGIN);
      } /* else if (user) {
        // If the user is signed in, redirect them to the ADMIN route
        navigate(ADMIN);
      } */
    }
  }, [location, user, isLoading]);

  if (isLoading) return <Loading />;

  return (
    <div>
      <CursorFollow />
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;

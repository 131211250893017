import React from 'react'
import './Footer.css'; 
import Row1 from './Row1/Row1';
import Row2 from './Row2/Row2';
import Row3 from './Row3/Row3';

const Footer = () => {
  return (
    <div className='footer footer-text'>
      {/* <Row1 /> */}
      <Row2 />
      <Row3 />
    </div>
  )
}

export default Footer

import React, {useEffect, useRef} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import './About.css';


const About = () => {

  const typedRef = useRef(null);

  useEffect(() => {
      const options = {
        strings: ['Passionate', 'Driven', 'Innovative', 'Creative', 'Customer-Focused', 'Solution-Oriented'],
        typeSpeed: 50,
        backSpeed: 40,
        loop:true,
      };

      const typed = new Typed(typedRef.current, options);

      return () => {
        typed.destroy();
      };
    }, []);

  return (
    <div className="container about-container">
      <div className="p-5">
        <div className="row">
          <div className="mt-5"></div>
          <h1 className='outline-header about-header'>We are <br /> <span ref={typedRef} className="typed primary-text"></span></h1>
          <div className="mt-5"></div>
          <div className="col-lg-6 about-main">
            <p className='white-text'>
               When you work with us, you work with a team dedicated to crafting exceptional web solutions that help businesses thrive in the digital age.
            </p>
            <Link to="/contact">
              <button className=" about-button white-text primary my-4">Learn More</button>
            </Link>
          </div>
          <div className="col-lg-6">
            <img
              src="../../media/aboutUs.avif"
              alt="About Us"
              className="img-fluid rounded"
            />
          </div>
        </div>
        <div className="row mt-5">
          <hr />
          <div className="col-md-12 white-text">
            <h1>Mission Statement</h1>
            <p className='p-3'>
              Our mission is to empower local businesses by creating innovative and user-friendly web solutions that enhance their online presence and drive success in the digital world. We are committed to providing top-notch web development services tailored to the unique needs of our clients in Windsor, Ontario, and beyond.
            </p>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default About;

import React, { useState, useEffect } from 'react';
import { fetchProjects, useRemoveProject } from '../../../hooks/useProject';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import { PROTECTED } from '../../../lib/routes';
import './Edit.css';

const ViewProjects = () => {
  const [projects, setProjects] = useState([]);
  const { removeProject: removeProjectHandler, loading, error } = useRemoveProject();

  useEffect(() => {
    const fetchData = async () => {
      const projectsData = await fetchProjects();
      setProjects(projectsData);
    };

    fetchData();
  }, []);

  const handleDelete = async (projectId, imageURL) => {
    const confirmed = window.confirm('Are you sure you want to delete this project?');

    if (confirmed) {
      // Call the removeProjectHandler from the useRemoveProject hook
      const removed = await removeProjectHandler(projectId, imageURL);

      if (removed) {
        // If the project is successfully removed, update the state
        setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="mt-5 hide">.</div> {/*  hidden in CSS, just for margin lmao */}
      <div className="row">
        {projects.map((project, index) => (
          <div key={project.id} className="col-md-4 mb-4">
            <div className="card h-100 d-flex flex-column justify-content-between">
              {/* Display project details */}
              <img src={project.image_url} className="card-img-top h-50" alt={project.title} />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <strong>Published by:</strong> {project.publishedBy}
                </li>
                <li className="list-group-item">
                  <strong>Technologies:</strong> {project.technologies}
                </li>
                <li className="list-group-item">
                  <strong>Date:</strong> {project.date.toDate().toLocaleDateString()} {/* Assuming date is a Firestore Timestamp */}
                </li>
              </ul>
              <div className="card-body">
                {/* Edit and Delete buttons */}
                <Link to={`${PROTECTED}/edit/${project.id}`} className="btn btn-primary mx-2">Edit</Link>
                <button
                  onClick={() => handleDelete(project.id, project.image_url)}
                  className="btn btn-danger"
                  disabled={loading}
                >
                  {loading ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewProjects;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Socials from '../../Socials/Socials';


const Row2 = () => {
  return (
    <>
      <div className="row mt-5 footer-main">
      <div className="overlay"></div>
        <div className="col-md-3 footer-logo">
          {/* Column 1: Company Logo */}
          <img src="../../media/Byte.png" alt="Company Logo" className='footer-logo' />
        </div>
        <div className="col-md-3 footer-content">
          {/* Column 2: Navigation */}
          <h3 className='fh-underline footer-header'>Navigation</h3>
          <div className='header-after'></div>
          <div><Link to="/home" className="footer-link">Home</Link></div>
          <div><Link to="/about" className="footer-link">About</Link></div>
          <div><Link to="/services" className="footer-link">Services</Link></div>
          <div><Link to="/work" className="footer-link">Our Work</Link></div>
          <div><Link to="/contact" className="footer-link">Contact</Link></div>
        </div>
        <div className="col-md-3 footer-content">
          {/* Column 3: Contact */}
          <h3 className='fh-underline footer-header'>Contact</h3>
          <div className='header-after'></div>
          <div className=""><a href="tel:+15199961094" className='footer-link'>(519) 996-1094</a></div>
          <div className=""><a href="mailto:bytebuddies1@gmail.com" className="footer-link">bytebuddies1@gmail.com</a></div> 
        </div>
        <div className="col-md-3 footer-content">
          {/* Column 4: Follow Us */}
          <h3 className='fh-underline footer-header'>Follow Us</h3>
          <div className='header-after'></div>
          <Socials />
        </div>
      </div>
    </>
   
  );
};

export default Row2;

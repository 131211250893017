import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { LOGIN } from '../../../lib/routes';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useFirebaseMessages from '../../../hooks/messages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MessageBoard.css';


const MessageBoard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 
  const [expandedMessages, setExpandedMessages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  

  // Use the useFirebaseMessages hook to fetch messages
  const { messages } = useFirebaseMessages();
  const sortedMessages = [...messages].sort((a, b) => a.date - b.date);
  // Filter messages where soft_delete is false
  const visibleMessages = sortedMessages.filter(message => !message.soft_delete);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate(LOGIN);
      }
      else {
        setLoading(false); // Set loading to false when authentication is checked
      }
    });
  }, [navigate]);

  const handleReadMore = (messageId) => {
    // Toggle the message's expanded state
    setExpandedMessages((prevExpandedMessages) =>
      prevExpandedMessages.includes(messageId)
        ? prevExpandedMessages.filter((id) => id !== messageId)
        : [...prevExpandedMessages, messageId]
    );
  };

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };
  
  

  return (
 
    <div className="container mt-5">
      <div className="mt-5"></div>
      <h1 className="text-white text-center">You've got mail!</h1>
     
      <table className="table table-bordered table-striped">
        <thead className="table-dark">
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Message</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {visibleMessages.map((message) => (
            <tr key={message.id}>
              <td className="align-middle">{message.name}</td>
              <td className="align-middle"><a href={`tel:${message.phone}`}>{message.phone}</a></td>
              <td className="align-middle"><a href={`mailto:${message.email}`}>{message.email}</a></td>
              <td className="align-middle">
                <Link to={`/protected/message/${message.id}`} className="mx-3">
                {/* Render only a part of the message content */}
                {expandedMessages.includes(message.id)
                  ? message.message
                  : `${message.message.slice(0, 25)}...`}
                {/* Display "Read More" button if message is longer */}
                {message.message.length > 25 && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleReadMore(message.id)}
                  >
                  </button>
                )}
                </Link> 
                
              </td>
              <td className="align-middle">
                <img
                  src={message.image_url}
                  alt=""
                  className="img-fluid"
                  style={{ maxWidth: '100px', cursor: 'pointer' }}
                  onClick={() => openImageModal(message.image_url)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    {/* Image Modal */}
    {selectedImage && (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center fw-bold fs-3">
              User Submitted Wireframe
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeImageModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={selectedImage} alt="" className="img-fluid" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  );
};

export default MessageBoard;

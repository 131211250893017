export const emailValidate = {
    required: {
        value: true,
        message: "Please enter an email address",
    },
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Email address is not valid",
    },
};
  
export const passwordValidate = {
    required: {
      value: true,
      message: "Please enter password",
    },
    minLength: {
      value: 6,
      message: "Password must be at least 6 characters long",
    },
};

export const nameValidate = {
  required: {
    value: true,
    message: "Please enter your name",
  },
  validate: {
    noNumbers: (value) =>
      !/\d/.test(value) ||
      "Name should not contain numbers",
  },
};

export const phoneValidate = {
  required: {
    value: true,
    message: "Please enter your phone number",
  },
  pattern: {
    value: /^[0-9]{10}$/, // Customize the pattern as needed for your phone number format
    message: "Phone number is not valid",
  },
};

import React, { useState, useEffect } from 'react';
import HomeSummary from './HomeSummary/HomeSummary';
import HomeSkills from './HomeSkills/HomeSkills';
import HomePortfolio from './HomePortfolio/HomePortfolio';
import './Home.css';
import HomeReviews from './HomeReviews/HomeReviews';
import AddReview from './HomeReviews/AddReview';

const Home = () => {
  const [portfolioLoaded, setPortfolioLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading delay (replace this with your actual loading logic)
    const delay = setTimeout(() => {
      setPortfolioLoaded(true);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(delay); // Cleanup the timeout on component unmount
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && portfolioLoaded) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [portfolioLoaded]);

  return (
    <div>
     <div className="hero">
      <img src={process.env.PUBLIC_URL + '/media/logo-hero.png'} alt="Logo Footer" />
      <h2 className='white-text'>just two buddies</h2>
      <h3 className='outline-header'>making beautiful and functional designs for your business or product</h3>
     </div>
       
      <HomeSummary />
      <HomeSkills />
      <HomePortfolio onLoaded={() => setPortfolioLoaded(true)} />
      <HomeReviews />
      <div id="review">
        <AddReview />
      </div>
    </div>
  );
};

export default Home;

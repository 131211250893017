import React from 'react';
import './Services.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const Services = () => {
  return (
    <div className='container white-dark-bg'>
      <div id="service-section">
        <h2 className='outline-header'>Services</h2>
        <p className='subheader white-text'>Take a look at some of the services we offer.</p>
        <div className="cards ">
          <div className="card-services ">
            <i className="fa-brands fa-react"></i>
            <div className="content">
              <h3>Custom Website Development</h3>
              <p className="">Looking for professional website development services? Whether you need a new website or want to enhance your existing one, we've got you covered.</p>
              <p className="">Our team specializes in creating custom, responsive websites tailored to your unique requirements. From sleek designs to powerful functionalities, we can bring your vision to life. Whether it's a personal blog, business site, or e-commerce platform, our website development services can meet your needs effectively.</p>
            </div>
          </div>

          <div className="card-services ">
            <i class="fa-solid fa-magnifying-glass"></i>
            <div className="content">
              <h3>SEO Enhancement Services</h3>
              <p className="">Looking to improve your website's search engine optimization? Whether you need a new website or an update to your existing one, our SEO enhancement services can assist you.</p>
              <p className="">Our experienced team specializes in optimizing websites for better visibility and ranking. We offer a range of SEO enhancement services tailored to meet your specific needs. Let us help you achieve higher search engine rankings and drive more organic traffic to your site.</p>
            </div>
          </div>

          <div className="card-services ">
            <i class="fa-regular fa-circle-up"></i>
            <div className="content">
              <h3>Website Update Services</h3>
              <p className="">Is your website in need of a refresh or update? Look no further - we're here to assist you.</p>
              <p className="">Whether you're looking for a minor facelift or a complete overhaul, our website update services are tailored to meet your specific needs. Our experienced team can enhance the look and functionality of your existing website, ensuring it stays modern, user-friendly, and aligned with the latest industry standards. Let us help you make your online presence even more impressive and effective.</p>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Services;

import React, { useState } from 'react';
import useAddComment from '../../../hooks/useAddComment';
import ReCAPTCHA from "react-google-recaptcha";
import './StarRating.css';


const AddReview = () => {
  const [newComment, setNewComment] = useState({
    username: '',
    rating: 0,
    comment: '',
    hoveredRating: 0,
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const { addComment, loading, error } = useAddComment();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewComment((prevComment) => ({
      ...prevComment,
      [name]: value,
    }));
  };
  const handleStarClick = (rating) => {
    console.log(rating);
    setNewComment({ ...newComment, rating });
  };
  const handleStarHover = (hoveredRating) => {
    setNewComment((prevComment) => ({
      ...prevComment,
      rating: hoveredRating,
    }));
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClassName = `fas fa-star mx-2 text-center${i <= newComment.rating ? ' hover-stars' : ''}${
        i <= newComment.hoveredRating ? ' hover-stars' : ''
      }`;
  
      stars.push(
        <i
          key={i}
          className={starClassName}
          onClick={() => handleStarClick(i)}
          onMouseEnter={() => handleStarHover(i)}
          onMouseLeave={() => handleStarHover(newComment.rating)}
        />
      );
    }
    return stars;
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }

    // Call the addComment function from the hook
    const success = await addComment(newComment);

    if (success) {
      // Reset the form after successful submission
      setNewComment({
        username: '',
        rating: '',
        comment: '',
      });
      // Reset reCAPTCHA value
      setRecaptchaValue(null);
    }
  };

  return (
    <div id="review" className="white-text review-font mt-5">
      <h3 className="mb-4 fw-bold fs-1">Add a Review</h3>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-8">
            <div>
              <label htmlFor="username" className="fs-5">
                Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={newComment.username}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <label htmlFor="rating" className="fs-5">
                Rating
              </label>
              <div className="star-rating fs-1">{renderStars()}</div>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="comment" className="fs-5">
            Your Review
          </label>
          <textarea
            className="form-control"
            maxLength={250}
            id="comment"
            name="comment"
            value={newComment.comment}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mt-3 mb-1">
          {/* Add reCAPTCHA component */}
          <ReCAPTCHA
            sitekey="6LfC8DIpAAAAAKx-E1cI-fC-AbiFcCsBqkBllxFS"
            onChange={handleRecaptchaChange}
          />
        </div> 
          <button type="submit" className="contact-button mt-3" disabled={loading}>
            Submit
          </button>
        {error && <p className="text-danger mt-3">Error: {error.message}</p>}
      </form>
    </div>
  );
};

export default AddReview;

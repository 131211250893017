import React from "react";

const Socials = () => {
 return (
    <p>
        {/* <a href='/' target='_blank'><i className="fa fa-light fa-linkedin fa-lg footer-link"></i></a>
        <a href='/' target='_blank'><i className="fa fa-light fa-facebook fa-lg footer-link"></i></a>  */}
        <a href='https://www.instagram.com/bytebuddies2023/' target='_blank' rel="noopener noreferrer"><i className="fa fa-light fa-instagram fa-lg footer-link"></i></a>
    </p>
 );
};

export default Socials;
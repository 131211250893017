import React, { useEffect, useState } from "react";
import './HomePortfolio.css';
import { fetchProjects } from "../../../hooks/useProject";
import { Link } from "react-router-dom";

const HomePortfolio = () => {
  const [projects, setProjects] = useState([]); // State to store fetched projects

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Call the fetchProjects function to get the projects data
        const projectsData = await fetchProjects();
        // Sort the projects by date in descending order
        const sortedProjects = projectsData.sort((a, b) => new Date(b.date) - new Date(a.date));
        // Take the first 3 projects
        const recentProjects = sortedProjects.slice(0, 3);
        setProjects(recentProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
  }, []); // Run only once on mount

  // This effect takes care of the homePortfolio header horizontal scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 992) {
        const scrollTop = window.scrollY;
        const viewportHeight = window.innerHeight;
        const featuredText = document.querySelector('.heading-text-featured');
        const workText = document.querySelector('.heading-text-work');

        if (featuredText && workText) {
          const scrollThreshold = viewportHeight * 0.1; 
          const scrollPosition = scrollTop - scrollThreshold;
          const featuredSpeed = 0.4; 

          featuredText.style.transform = `translateX(${scrollPosition * -featuredSpeed}px)`;
          workText.style.transform = `translateX(${scrollPosition * 0.2}px)`;
        }
      } else {
        const featuredText = document.querySelector('.heading-text-featured');
        const workText = document.querySelector('.heading-text-work');

        if (featuredText && workText) {
          featuredText.style.transform = 'translateX(0)';
          workText.style.transform = 'translateX(0) translateY(0)';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return (
      <div className="HomePortfolio">
    
        <div className="portfolioHeader">
          <h1 className="parallax-text heading-text-featured white-text">featured</h1>
          <h1 className="parallax-text heading-text-work">work</h1>
        </div>
        <div className="projects">
        {projects.map((project) => (
          <section key={project.id} name={project.title} className="project">
            <div className="project-image-container">
              <div
                className="project-image"
                style={{ backgroundImage: `url(${project.image_url})` }}
              ></div>
              <h2 className="project-title front">{project.title}</h2>
              <h2 className="project-title back">{project.title}</h2>
            </div>
            <div className="project-link">
              <Link to={`/project/${project.id}`} className="mx-3"><span className="underline white-text">see more</span></Link>
            </div>
          </section>
        ))}
      </div>
      </div>
    );
  };
  
  export default HomePortfolio;
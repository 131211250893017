import React, { useState, useEffect } from 'react';

const CursorFollow = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  useEffect(() => {
    const updatePosition = (e) => {
      requestAnimationFrame(() => {
        setPosition({ x: e.clientX, y: e.clientY });
      });
    };

    document.addEventListener('mousemove', updatePosition);

    return () => {
      document.removeEventListener('mousemove', updatePosition);
    };
  }, []);

  useEffect(() => {
    const handleLinkHover = () => {
      setIsLinkHovered(true);
    };

    const handleLinkLeave = () => {
      setIsLinkHovered(false);
    };

    const links = document.querySelectorAll('a'); // Add a class or specific selector for links

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleLinkHover);
      link.addEventListener('mouseleave', handleLinkLeave);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener('mouseenter', handleLinkHover);
        link.removeEventListener('mouseleave', handleLinkLeave);
      });
    };
  }, []);

  const cursorStyle = {
    width: isLinkHovered ? '50px' : '33px', // Expand the circle when a link is hovered
    height: isLinkHovered ? '50px' : '33px',
    backgroundColor: 'grey',
    opacity: '65%',
    borderRadius: '50%',
    position: 'fixed',
    transform: `translate(${position.x - (isLinkHovered ? 25 : 16)}px, ${position.y - (isLinkHovered ? 25 : 16)}px)`,
    pointerEvents: 'none',
    transition: 'width 0.3s, height 0.3s', // Add a smooth transition effect for width and height
    zIndex: '999',
  };

  return <div className='mobile-hidden' style={cursorStyle}></div>;
};

export default CursorFollow;

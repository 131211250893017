import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from 'react-hook-form';
import './Contact.css';
import Typed from 'typed.js';
import useFirebaseMessages from '../../hooks/messages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailValidate, phoneValidate, nameValidate } from '../../util/form-validate';
import ReCAPTCHA from "react-google-recaptcha";




const Contact = () => {
  const typedRef = useRef(null);
  const { messages, addMessage } = useFirebaseMessages();
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);


  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleSuccessfulSubmit = () => {
    toast.success('Message sent successfully!', {
      position: 'top-center',
      autoClose: 5000,
      closeButton: true,
      className: 'mb-5',
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    reset();
  }

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleRemoveImage = () => {
    setImageFile(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { name, phone, email, message } = data;
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA verification.");
      setLoading(false);
      return;
    }
    if (errors.name || errors.phone || errors.email || errors.message) {
      // Validation failed, show an error toast
      toast.error('Please correct the form errors.', {
        position: 'top-center',
        autoClose: 5000,
        closeButton: true,
        hideProgressBar: false,
        closeOnClick: true,
        className: 'mb-5',
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const newMessage = {
      name,
      phone,
      email,
      message,
    };

    if (imageFile) {
      newMessage.image = imageFile;
    }

    try {
      await addMessage(newMessage);
      handleSuccessfulSubmit();

      // Clear the form and reset the image
      setImageFile(null);
    } catch (error) {
      /* console.error('Error sending the message:', error); */
    } finally {
      setLoading(false);
    }
  };



  return (
    <div className="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6 contact-left">
            <h2>Have a <span>Project?</span></h2>
            <h3 className="text-center mb-4">email, call, or message</h3>
            <p>Are you in search of a tailored solution to address the distinctive digital requirements of your business? Don't hesitate to reach out to us now.</p>
            <p>
              <strong className="primary-text">E:</strong> <a className='white-text text-decoration-none' href="mailto:bytebuddies1@gmail.com">bytebuddies1@gmail.com</a> 
              <br />
              <strong className="primary-text">P:</strong> <a className='white-text text-decoration-none' href="tel:+519-996-1094">519-996-1094</a>  
            </p>
            {/* We are <span ref={typedRef} className="typed"></span> */}
          </div>

          <div className="col-md-6 white-text contact-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  {...register('name', nameValidate)}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  required
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  name="phone"
                  {...register('phone', phoneValidate)}
                  className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                  required
                />
                {errors.phone && (
                  <div className="invalid-feedback">{errors.phone.message}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  {...register('email', emailValidate)}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  required
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  {...register('message', { required: 'Please enter a message' })}
                  className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                  required
                ></textarea>
                {errors.message && (
                  <div className="invalid-feedback">{errors.message.message}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="image" className="form-label">
                  Upload Image (optional)
                </label>
                <input
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                  className="form-control"
                />
                {imageFile && (
                  <div>
                    <img
                      src={URL.createObjectURL(imageFile)}
                      alt="Uploaded Image"
                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                    <button type="button" onClick={handleRemoveImage}>
                      Remove Image
                    </button>
                  </div>
                )}
              </div>
              <div className="mb-3">   
                  <ReCAPTCHA
                    sitekey="6LfC8DIpAAAAAKx-E1cI-fC-AbiFcCsBqkBllxFS"
                    onChange={handleRecaptchaChange}
                  />
              </div>
              {loading ? (
               <>Loading</>
              ) : (
                  <button type="submit" className="contact-button mb-4" disabled={loading}>
                    Send Message
                  </button>                            
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;

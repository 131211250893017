import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddProject from '../projects/AddProject';
import ViewProjects from '../projects/ViewProjects';
import MessageBoard from '../messages/MessageBoard';

const Admin = () => {
  const [key, setKey] = useState('addProject');

  return (
    <div className="container mt-5">
      <Tabs
        id="admin-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 white-text"
      >
        <Tab eventKey="addProject" title="Add Project" >
          <AddProject />
        </Tab>
        <Tab eventKey="viewProjects" title="View Projects">
          <ViewProjects />
        </Tab>
        <Tab eventKey="messageBoard" title="Message Board">
          <MessageBoard />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Admin;

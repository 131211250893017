import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ColourSwitcher.css';
import './theme.css';

const ColourSwitcher = () => {
  const [isStyleSwitcherOpen, setIsStyleSwitcherOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem('isDarkMode')) || true
  );
  const [selectedColor, setSelectedColor] = useState(
    localStorage.getItem('selectedColor') || 'color-3'
  );
  const [isChecked, setIsChecked] = useState(isDarkMode);

  const setActiveStyle = (color) => {
    // Toggle "disabled" attribute for each style link
    const alternateStyles = document.querySelectorAll('.alternate-style');
    alternateStyles.forEach((style) => {
      const styleColor = style.getAttribute('title');
      if (color === styleColor) {
        style.removeAttribute('disabled');
      } else {
        style.setAttribute('disabled', 'true');
      }
    });
  };

  const toggleStyleSwitcher = () => {
    setIsStyleSwitcherOpen(!isStyleSwitcherOpen);
  };

  const toggleDayNightMode = () => {
    setIsDarkMode(!isDarkMode);
    console.log('toggle day/night');
  };

  useEffect(() => {
    const body = document.body;

    if (isDarkMode) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }

    // Save preferences to localStorage
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    localStorage.setItem('selectedColor', selectedColor);
  }, [isDarkMode, selectedColor]);

  const handleToggle = () => { 
    const newValue = !isChecked;
    setIsChecked(newValue);
    
    if(newValue !== isDarkMode) {
      toggleDayNightMode();
    }
  };

  return (
    <>
    <div className={`style-switcher ${isStyleSwitcherOpen ? 'open' : ''}`}>
      {/* <div className='toggle-container'>
        <div className="style-switcher-toggler s-icon" onClick={toggleStyleSwitcher}>
          <i className='fas fa-cog fa-spin primary-text' ></i>
        </div> 
      </div>
      <div className={`color-container black-white-bg ${isStyleSwitcherOpen ? 'open' : ''}`}> */}
        <div className="day-night">
          <input className='toggle-checkbox'
                type="checkbox"
                id="toggle"
                checked={isChecked}
                onChange={handleToggle}></input>
          <label className='toggle-label' htmlFor="toggle"></label>
          {/* <i className={`fas primary-text ${isDarkMode ? 'fa-sun' : 'fa-moon'}`}></i> */}
        </div>
        {/* <h4 className='dark-text'>Theme Colours</h4>
        <div className="colors">
          {/* Add your color span elements here
          <span className="color-1" onClick={() => { setActiveStyle('color-1'); setSelectedColor('color-1'); }}></span>
          <span className="color-2" onClick={() => { setActiveStyle('color-2'); setSelectedColor('color-2'); }}></span>
          <span className="color-3" onClick={() => { setActiveStyle('color-3'); setSelectedColor('color-3'); }}></span>
          <span className="color-4" onClick={() => { setActiveStyle('color-4'); setSelectedColor('color-4'); }}></span>
        </div>
      </div>
       Dynamically update the --skin-color in theme.css based on the selected color */}
       <style>
        {`:root {
          --skin-color: ${selectedColor === 'color-1' ? '#ec1839' :
                         selectedColor === 'color-2' ? '#f021b2' :
                         selectedColor === 'color-3' ? '#37b182' :
                         selectedColor === 'color-4' ? '#1854b4' : '#37b182'};
          --dark-mode-button-color: ${isDarkMode ? '#ffffff' : '#000000'};
        }`}
      </style>
    </div>
    </>
  );
};

export default ColourSwitcher;

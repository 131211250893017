import React, { useEffect, useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { useParams} from 'react-router-dom';
import { db } from '../../../lib/firebase';
import Loading from '../../loading/Loading';
import './MessageBoard.css';
import useSoftDeleteMessage from '../../../hooks/useDeleteMessage'; // Update the path to the actual location of the hook

const Message = () => {
  const { id } = useParams();
  const [itemData, setItemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // Soft delete hook
  const { isSoftDeleted, error: softDeleteError, setMessageId } = useSoftDeleteMessage();

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const itemRef = doc(db, 'messages', id);
        const itemDoc = await getDoc(itemRef);
        if (itemDoc.exists()) {
          const item = itemDoc.data();
          setItemData(item);
        } else {
          setError('Item not found.');
        }
      } catch (error) {
        setError('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItemData();
  }, [id]); // Include isSoftDeleted in the dependency array

  const handleSoftDelete = () => {
    // Soft delete will be triggered when this button is clicked
    setMessageId(id);
  };

  if (loading) {
    return <div><Loading /></div>;
  }

  if (!itemData) {
    return <div>Item not found.</div>;
  }
  

  return (
    <div className="container mt-5 white-black-bg">
        <>
          <div className="mt-5 hide">.</div>
          <p className="fs-3 m-5 white-text">Incoming message from {itemData.name}!</p>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-3">
              <a href={`tel:${itemData.phone}`}>{itemData.phone}</a>
            </div>
            <div className="col-md-12 text-center mb-3">
              <a href={`mailto:${itemData.email}`}>{itemData.email}</a>
            </div>
            <div className="col-md-12 text-center mb-3">
              <p className="white-text">{itemData.message}</p>
            </div>
            <div className="col-md-12 text-center mb-3">
              {itemData.image_url && (
                <img
                  src={itemData.image_url}
                  alt={itemData.name}
                  className="img-fluid"
                  style={{ height: '600px', width: '100%' }}
                />
              )}
            </div>
            <div className="col-md-12 text-center mb-3">
              <button
                className="btn btn-danger"
                onClick={handleSoftDelete} // Call handleSoftDelete on button click
              >
                Soft Delete
              </button>
            </div>
            <div className="col-md-12 text-center">
              <hr className="white-text" />
            </div>    
          </div>
        </> 
    </div>
  );
};

export default Message;

import {
    getFirestore,
    collection,
    addDoc,
    serverTimestamp,
  } from 'firebase/firestore';
  import { app } from '../lib/firebase';
  
  const useAddComment = () => {
    const addComment = async (newComment) => {
      try {
        const firestore = getFirestore(app);
        const collectionRef = collection(firestore, 'comments');
    
        // Add timestamp to the newComment
        newComment.date = serverTimestamp();
        // Add the newComment to the 'comments' collection in Firestore
        await addDoc(collectionRef, newComment);
    
        return true;
      } catch (error) {
        console.error('Error adding review:', error);
        return false;
      }
    };
  
    return { addComment };
  };
  
  export default useAddComment;

import React, { useEffect, useState, useRef } from "react";
import { fetchProjects } from "../../hooks/useProject";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Project.css';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const projectsData = await fetchProjects();
                const sortedProjects = projectsData.sort((a, b) => new Date(b.date) - new Date(a.date));
                setProjects(sortedProjects);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchData();
    }, []);

    const handleScroll = () => {
        const projectRows = document.querySelectorAll('.project-row');

        projectRows.forEach((row, index) => {
            const rect = row.getBoundingClientRect();

            if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
                setVisibleIndex(index);
            }
        });
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container prcontent mt-5 text-light">
            <h1 className='prheader about-header primary-text mb-4'>Our Projects</h1>
            <div className="pr-container">
            {projects.map((project, index) => (
                <a href={`/project/${project.id}`} class="pro-link">
                    <div className="label-div">
                        <div className="body-5">{project.technologies}</div>
                    </div>
                    <div className="pro-name-div" >
                        <h2 className="pro-heading">{project.title}<br /></h2>
                    </div>
                    <div className="pro-bg-image-wrap">
                        <div className="pro-bg-image" style={{ backgroundImage: `url(${project.image_url})` }}></div>
                    </div>
                    <div className="pro-year-txt">{project.date.toDate().toLocaleDateString()}</div>
                </a>

                /* <div
                    key={project.id}
                    className={`row project-row ${index % 2 === 0 ? 'flex-row-reverse' : ''} ${visibleIndex === index ? 'visible' : ''}  p-4 rounded`}
                >
                    <div className="col-md-6">

                        <iframe src={project.project_url}
                            title="Live Demo"
                            width="100%"
                            height="400px"  // Adjust the height as needed
                            frameBorder="0"
                            allowFullScreen
                            className="mb-3"
                            >
                        </iframe>
                    </div>
                    <div className="col-md-6">
                        <h5 className="prheader primary-text">{project.title}</h5>
                        <p className="white-text">{project.description}</p>
                        <p className="white-text"><strong className="primary-text">Date:</strong> {project.date.toDate().toLocaleDateString()}</p>
                        <p className="white-text"><strong className="primary-text">Published By:</strong> {project.publishedBy}</p>
                        <p className="white-text"><strong className="primary-text">Technologies:</strong> {project.technologies}</p>
                        <button className="primary"><a className="text-decoration-none white-text" href={project.project_url} target="_blank" rel="noopener noreferrer">View Project</a></button>

                    </div>
                </div> */
            ))}
            </div>
        </div>
    );
}

export default Projects;

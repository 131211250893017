import React, { useEffect, useRef, useState } from "react";
import './HomeSkills.css';

const HomeSkills = () => {
  const treeRef = useRef(null);
  const [isSummaryFixed, setIsSummaryFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const tree = treeRef.current;
      const branches = tree.querySelectorAll('.branch');

      branches.forEach((branch, index) => {
        const rect = branch.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          branch.classList.add('grow');
        } else {
          branch.classList.remove('grow');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSummaryFixed]);

  useEffect(() => {
    const handleSummaryFixed = () => {
      // Adjust this value based on when the summary is considered fixed
      const summaryFixedThreshold = 660;
      setIsSummaryFixed(window.scrollY >= summaryFixedThreshold);
    };

    window.addEventListener('scroll', handleSummaryFixed);

    return () => {
      window.removeEventListener('scroll', handleSummaryFixed);
    };
  }, []);

  return (
    <div className={`HomeSkills ${isSummaryFixed ? 'sticky' : ''}`}>
      <div className="tree-container" ref={treeRef}>
        <div className="timeline">
          <div className="timeline-content odd">
            <h2>Website Creation</h2>
            <p>Learn about designing and building websites from scratch.</p>
          </div>
          <div className="timeline-middle"></div>
          <div className="timeline-content even">
            <h2>SEO Optimization</h2>
            <p>Discover techniques to optimize your website for search engines.</p>
          </div>
          <div className="timeline-middle"></div>
          <div className="timeline-content odd">
            <h2>Responsive Web Development</h2>
            <p>Explore how to create websites that work seamlessly across devices.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSkills;

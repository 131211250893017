import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './HomeReviews.css'; 
import ColourSwitcher from '../../colourSwitcher/ColourSwitcher';
import useComments from "../../../hooks/useFetchComments";
import StarRating from "./StarRating";

const HomeReviews = () => {
  const { comments, loading } = useComments();

  // Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="HomeReviews" className="HomeReviews">
      <div className="container mt-5">
        {comments.length > 0 && (
          <h3 className="reviews-header">Don't just believe us!</h3>
        )}
        <div className="row">
          <div className="col-md-12">
            {comments.length > 0 ? (
              <OwlCarousel id="customer-testimonials" className="owl-carousel owl-theme" {...options}>
                {comments.map((comment) => (
                  <div key={comment.id} className="item">
                    <div className="main-review shadow-effect">
                      <div className="testimonial-name">
                        <h5>{comment.username}</h5>
                        <StarRating rating={comment.rating} />
                      </div>
                      <p>{comment.comment}</p>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <p className="hide mt-3">No comments available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeReviews;

import React, { useState, useRef } from 'react';
import { addProject } from '../../../hooks/useProject';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Edit.css';
import Loading from '../../loading/Loading';

const AddProject = () => {
  const { newProject } = addProject();
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleSuccessfulSubmit = () => {
    toast.success('Project added successfully!', {
      position: 'top-center',
      autoClose: 5000,
      closeButton: true,
      className: 'mb-5',
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    reset();
  }

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleRemoveImage = () => {
    setImageFile(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const { title, description, publishedBy, project_url, technologies } = data;

    if (errors.title || errors.description || errors.publishedBy || errors.technologies) {
      // Validation failed, show an error toast
      toast.error('Please correct the form errors.', {
        position: 'top-center',
        autoClose: 5000,
        closeButton: true,
        hideProgressBar: false,
        closeOnClick: true,
        className: 'mb-5',
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const newProject = {
      title,
      description,
      project_url,
      publishedBy,
      technologies,
    };

    if (imageFile) {
      newProject.image = imageFile;
    }

    try {
      await addProject(newProject);
      handleSuccessfulSubmit();

      // Clear the form and reset the image
      setImageFile(null);
    } catch (error) {
      console.error('Error adding project:', error);
      toast.error('Error adding project. Please try again.', {
        position: 'top-center',
        autoClose: 5000,
        closeButton: true,
        hideProgressBar: false,
        closeOnClick: true,
        className: 'mb-5',
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false); // Set loading to false when done submitting
    }
  };

  return (
    <div className="container text-white mt-5">
      <div className="mt-5 hide">.</div>
      <div className="mt-2 hide">.</div>
      <h2>Add Project</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title:
          </label>
          <input
            type="text"
            name="title"
            {...register('title', { required: 'Please enter a title' })}
            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
            required
          />
          {errors.title && (
            <div className="invalid-feedback">{errors.title.message}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description:
          </label>
          <textarea
            name="description"
            {...register('description', { required: 'Please enter a description' })}
            className={`form-control ${errors.description ? 'is-invalid' : ''}`}
            required
          ></textarea>
          {errors.description && (
            <div className="invalid-feedback">{errors.description.message}</div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="project_url" className="form-label">
            Project URL:
          </label>
          <input
            type="text"
            name="project_url"
            {...register('project_url', { required: 'Please enter the url' })}
            className={`form-control ${errors.project_url ? 'is-invalid' : ''}`}
            required
          />
          {errors.project_url && (
            <div className="invalid-feedback">{errors.project_url.message}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="publishedBy" className="form-label">
            Published By:
          </label>
          <input
            type="text"
            name="publishedBy"
            {...register('publishedBy', { required: 'Please enter the publisher' })}
            className={`form-control ${errors.publishedBy ? 'is-invalid' : ''}`}
            required
          />
          {errors.publishedBy && (
            <div className="invalid-feedback">{errors.publishedBy.message}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="technologies" className="form-label">
            Technologies (comma-separated):
          </label>
          <input
            type="text"
            name="technologies"
            {...register('technologies', { required: 'Please enter technologies' })}
            className={`form-control ${errors.technologies ? 'is-invalid' : ''}`}
            required
          />
          {errors.technologies && (
            <div className="invalid-feedback">{errors.technologies.message}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Upload Image
          </label>
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            className="form-control" 
            required
          />
          {imageFile && (
            <div>
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Uploaded Image"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <button type="button" onClick={handleRemoveImage}>
                Remove Image
              </button>
            </div>
          )}
        </div>
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn btn-primary mb-4" disabled={loading}>
          Add Project
      </button>
        )}
        
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddProject;
